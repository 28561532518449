import React from "react"
import { motion } from "framer-motion"

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.4,
      delayChildren: 0.2,
    },
  },
}
const item = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [0, 1],
  },
}

const Logo = ({className}) => (
  <motion.svg
    className={className}
    variants={container}
    initial="hidden"
    animate="visible"
    width="100%"
    height="100%"
    viewBox="0 0 535.3 191.89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    
    
    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
      fill="#027835" d="M71.01 166.78C48.31 153.62 33 129.07 33 101c0-16.53 5.32-31.83 14.31-44.31L45.3 36.71l-21.78 3.37C11.24 57.27 4 78.31 4 101c0 38.08 20.37 71.48 50.79 89.89l16.22-24.11z"
    />

    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
      d="M65.33 170.27C41.83 156.57 26 131.11 26 102c0-43.56 35.44-79 79-79 30.13 0 56.37 16.96 69.69 41.83l16.37-17.54C172.93 18.88 141.13 0 105 0 48.76 0 3 45.76 3 102c0 37.04 19.85 69.53 49.47 87.4l12.86-19.13z" fill="#a6a3a2"
    />
    
    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
      d="M25.98 102.04c0-20.41 7.78-39.04 20.53-53.07l-1.23-12.21-21.24 3.29c-2.63 3.43-5.06 7.03-7.25 10.79a104.38 104.38 0 00-12.81 50.2c0 36.57 18.8 68.82 47.23 87.63.41.26.82.52 1.24.78l12.86-19.13c-23.49-13.71-39.33-39.17-39.33-68.28z" fill="#7c7b7b"
    />
    
    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
      fill="#027835" d="M92.08 120.61c4.89 4.89 10.79 7.34 17.7 7.34s12.81-2.45 17.7-7.34l.1-.1 13.3 13.3-.1.1c-8.54 8.54-18.88 12.81-31.01 12.81-12.13 0-22.47-4.27-31.01-12.81-8.54-8.54-12.81-18.88-12.81-31.01s4.27-22.46 12.81-31.01c8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81 8.54 8.54 12.81 18.88 12.81 31.01 0 2.09-.2 4.17-.59 6.26H85.52c1.05 4.31 3.23 8.13 6.56 11.45zm35.41-35.41c-4.89-4.89-10.79-7.34-17.7-7.34s-12.81 2.45-17.7 7.34c-1.63 1.63-2.97 3.36-4.01 5.18h43.43a25.377 25.377 0 00-4.02-5.18zM166.12 77.87c0-12.13 4.27-22.46 12.81-31.01 8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81l.1.1-13.3 13.3-.1-.1c-4.89-4.89-10.79-7.34-17.7-7.34s-12.81 2.45-17.7 7.34c-3.33 3.33-5.51 7.14-6.55 11.44h43.04v18.78h-43.82v56.34h-18.78V77.87zM241.25 77.87c0-12.13 4.27-22.46 12.81-31.01 8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81l.1.1-13.3 13.3-.1-.1c-4.89-4.89-10.79-7.34-17.71-7.34-6.91 0-12.81 2.45-17.7 7.34-3.33 3.33-5.51 7.14-6.55 11.44h43.04v18.78h-43.82v56.34h-18.78V77.87zM325.76 52.83c-2.61 0-4.83-.91-6.65-2.74-1.83-1.83-2.74-4.04-2.74-6.65 0-2.61.91-4.83 2.74-6.65 1.82-1.83 4.04-2.74 6.65-2.74 2.61 0 4.83.91 6.65 2.74 1.83 1.83 2.74 4.04 2.74 6.65 0 2.61-.91 4.83-2.74 6.65-1.83 1.83-4.04 2.74-6.65 2.74zm9.39 6.26v87.64h-18.78V59.09h18.78z"
    />
    
    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
     fill="#4ead33" d="M90.12 118.86c4.89 4.89 10.79 7.34 17.7 7.34s12.81-2.45 17.7-7.34l.1-.1 13.3 13.3-.1.1c-8.54 8.54-18.88 12.81-31.01 12.81-12.13 0-22.47-4.27-31.01-12.81-8.53-8.53-12.8-18.87-12.8-31s4.27-22.46 12.81-31.01c8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81 8.54 8.54 12.81 18.88 12.81 31.01 0 2.09-.2 4.17-.59 6.26H83.57c1.04 4.3 3.22 8.12 6.55 11.44zm35.41-35.41c-4.89-4.89-10.79-7.34-17.7-7.34s-12.81 2.45-17.7 7.34c-1.63 1.63-2.97 3.36-4.01 5.18h43.43c-1.05-1.82-2.39-3.55-4.02-5.18zM164.17 76.12c0-12.13 4.27-22.46 12.81-31.01 8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81l.1.1-13.3 13.3-.1-.1c-4.89-4.89-10.79-7.34-17.7-7.34s-12.81 2.45-17.7 7.34c-3.33 3.33-5.51 7.14-6.55 11.44h43.04v18.78h-43.82v56.34h-18.78V76.12zM239.29 76.12c0-12.13 4.27-22.46 12.81-31.01 8.54-8.54 18.88-12.81 31.01-12.81 12.13 0 22.46 4.27 31.01 12.81l.1.1-13.3 13.3-.1-.1c-4.89-4.89-10.79-7.34-17.71-7.34-6.91 0-12.81 2.45-17.7 7.34-3.33 3.33-5.51 7.14-6.55 11.44h43.04v18.78h-43.82v56.34H239.3V76.12zM323.8 51.08c-2.61 0-4.83-.91-6.65-2.74-1.83-1.83-2.74-4.04-2.74-6.65 0-2.61.91-4.83 2.74-6.65 1.82-1.83 4.04-2.74 6.65-2.74 2.61 0 4.83.91 6.65 2.74 1.83 1.83 2.74 4.04 2.74 6.65 0 2.61-.91 4.83-2.74 6.65-1.82 1.83-4.04 2.74-6.65 2.74zm9.39 6.26v87.64h-18.78V57.34h18.78zM67.01 167.78C44.31 154.62 29 130.07 29 102c0-16.53 5.32-31.83 14.31-44.31L41.3 37.71l-21.78 3.37C7.24 58.27 0 79.31 0 102c0 38.08 20.37 71.48 50.79 89.89l16.22-24.11z"
    />
    
    <motion.path
      variants={item}
      transition={{ ease: "easeOut", duration: 2 }}
      fill="#008cbd" d="M425.88 131.41a47.144 47.144 0 01-24.05 6.57c-26.17 0-47.46-21.29-47.46-47.46s21.29-47.46 47.46-47.46c8.93 0 17.28 2.48 24.42 6.78l6.53-6.53a56.188 56.188 0 00-30.96-9.25c-31.18 0-56.46 25.28-56.46 56.46s25.28 56.46 56.46 56.46c11.28 0 21.78-3.32 30.6-9.02l-6.54-6.55z M479.13 43.64c26.01 0 47.17 21.16 47.17 47.17s-21.16 47.17-47.17 47.17-47.17-21.16-47.17-47.17 21.16-47.17 47.17-47.17m0-9c-31.02 0-56.17 25.15-56.17 56.17s25.15 56.17 56.17 56.17c31.02 0 56.17-25.15 56.17-56.17s-25.15-56.17-56.17-56.17z"
    />
    

  </motion.svg>
)

export default Logo
